<template>
  <div>
    <!-- <Navbar class="animated fadeInDown mb-2" /> -->
    <div
      v-if="orders && orders.length && designerLoad"
      class="container-fluid px-0"
    >
      <Navbar class="mb-2 w-100" />

      <div class="row mb-4 justify-content-around">
        <DesignerLoad
          v-for="designer in designers"
          :key="designer.id"
          :orders="ordersDesignerLoad"
          :designer="designer"
          :baketPoint="baketPoint"
          class="mb-1 col-sm-5"
        />
      </div>
    </div>
    <div v-if="report">
      <Report :baketPoint="baketPoint" />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import DesignerLoad from "@/components/app/DesignerLoad";
import Report from "@/components/app/Report";
import basketJSON from "@/components/basket.json";
import { bus } from "@/main";
export default {
  components: {
    Navbar,
    DesignerLoad,
    Report,
  },
  data() {
    return {
      orders: null,
      ordersDesignerLoad: null,
      designers: [
        {
          name: "Алена Дзюба",
          id: "1539083520988",
          plan: 50,
          planAsText: "50 баллов",
        },
        {
          name: "Азура",
          id: "1660543483156",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Екатерина Черненко",
          id: "1660747961248",
          plan: 32,
          planAsText: "32 балла",
        },
          {
          name: "Alina_Galikeeva",
          id: "1650354772252",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Фарида Сираева",
          id: "1653653337197",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Фаина Червова",
          id: "1644848510234",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Анастасия Лысенко",
          id: "1555943482612",
          plan: 50,
          planAsText: "50 баллов",
        },
        {
          name: "Татьяна Кунгурцева",
          id: "1594730911613",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Дима",
          id: "1625815081116",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Катя Подольская",
          id: "1599117071455",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Ложкина Анастасия",
          id: "1600187208371",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Валерия",
          id: "1603088212393",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Артем Камаев",
          id: "1599635181887",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Яковлева (фриланс)",
          id: "1568099679333",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Анастасия Пантюшина",
          id: "1500376266861",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Сидорова (фриланс)",
          id: "1594917392344",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Вероника (фриланс)",
          id: "1594980044208",
          plan: 32,
          plan: "32 балла",
        },
        {
          name: "Jeanne",
          id: "1596538587913",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Ульяна (фриланс)",
          id: "1607416291452",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Андрей Епифанов",
          id: "1607673193718",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "nrrn",
          id: "1607674247289",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Дарья Ширкова",
          id: "1607674677781",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Герман (фриланс)",
          id: "1605767632576",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Марат (НН)",
          id: "1611237400998",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Дарья (НН)",
          id: "1611237444014",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Роман Петров",
          id: "1617708752234",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Роман Урк",
          id: "1617106396245",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Софья (НН)",
          id: "1611298923259",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Лилия (НН)",
          id: "1611306176163",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Надежда (НН)",
          id: "1611313191447",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Снежана (НН)",
          id: "1611323224571",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Юлия Гусева",
          id: "1611756861823",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Лиза Знайда",
          id: "1612535706819",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Александра офис",
          id: "1618812771520",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Лисицкая",
          id: "1618046038345",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Саша Чивиева",
          id: "1613570822840",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Olivia Evans",
          id: "1614773609024",
          plan: 45,
          planAsText: "45 баллов",
        },
        {
          name: "Амина",
          id: "1628493682159",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Максим",
          id: "1693906709344",
          plan: 32,
          planAsText: "32 балла",
        },
           {
          name: "Олеся",
          id: "1644838621083",
          plan: 32,
          planAsText: "32 балла",
        },
          {
          name: "Алина Бибоева",
          id: "1697613312960",
          plan: 32,
          planAsText: "32 балла",
        },
          {
          name: "Полина Лепкова",
          id: "1622560085305",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Виктория Санторская",
          id: "1697613289809",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Гюнель",
          id: "1694883137051",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Даша Холодкова",
          id: "1694883142326",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Наталья Колмакова",
          id: "1706799430646",
          plan: 32,
          planAsText: "32 балла",
        },
           {
          name: "Лолита",
          id: "1706861416463",
          plan: 32,
          planAsText: "32 балла",
        },
           {
          name: "Анна Заднепровская",
          id: "1706861422357",
          plan: 32,
          planAsText: "32 балла",
        },
            {
          name: "Дмитрий Соломин",
          id: "1707743237200",
          plan: 32,
          planAsText: "32 балла",
        },
           {
          name: "Дорошенко Екатерина",
          id: "1709295384179",
          plan: 32,
          planAsText: "32 балла",
        },   {
          name: "Александр Катаев",
          id: "1713279326086",
          plan: 32,
          planAsText: "32 балла",
        },   {
          name: "Иванченко Екатерина",
          id: "1713279358769",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Александра Грызова",
          id: "1715618977060",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Бартенева Анна",
          id: "1716903108552",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Водолазская Юлия",
          id: "1717074524720",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Александра Павлова",
          id: "1720862536119",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Волошина Карина",
          id: "1720876936015",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Ирина Крылова",
          id: "1721201198827",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Колесова Анна",
          id: "1721218523154",
          plan: 32,
          planAsText: "32 балла",
        },
        {
          name: "Анастасия Горбань",
          id: "1722784398537",
          plan: 32,
          planAsText: "32 балла",
        },
         {
          name: "Ольга Седёлкина",
          id: "1723295426324",
          plan: 32,
          planAsText: "32 балла",
        },
      ],
      baketPoint: { basket4: [], basket2: [], basket1: [] },
      designerLoad: true,
      report: false,
    };
  },
  methods: {
    sortYesterday() {
      bus.$emit("yesterday");
    },
    sortToday() {
      bus.$emit("today");
    },
    sortTomorrow() {
      bus.$emit("tomorrow");
    },
    sortAfterTomorrow() {
      bus.$emit("afterTomorrow");
    },
  },
  created() {
    bus.$on("openDesignerLoadB", () => {
      if (this.designerLoad === false) {
        this.report = !this.report;
        this.designerLoad = !this.designerLoad;
      }
    });
    bus.$on("openReportB", () => {
      if (this.report === false) {
        this.report = !this.report;
        this.designerLoad = !this.designerLoad;
      }
    });
  },
  async mounted() {
    const usedWorker = await localStorage.getItem("user-status");
    if (usedWorker === "20") {
      this.manufacture123435 = true;
      this.orders = await request("http://45.84.227.225/api/contacts");
      // this.orders = await request("http://192.168.1.68/api/contacts");

      this.ordersDesignerLoad = this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (
            field.fieldId === 86 &&
            (field.value === today ||
              field.value === yesterday ||
              field.value === tomorrow ||
              field.value === afterTomorrow)
          )
            return true;
        }
      });
      for (let j in basketJSON.basket.goods) {
        let item = basketJSON.basket.goods[j];
        if (item.point === 4) this.baketPoint.basket4.push(item.goods_article);
        if (item.point === 2) this.baketPoint.basket2.push(item.goods_article);
        if (item.point === 1) this.baketPoint.basket1.push(item.goods_article);
      }
      for (let j in basketJSON.basket.additional_goods) {
        let item = basketJSON.basket.additional_goods[j];
        if (item.point === 4) this.baketPoint.basket4.push(item.goods_article);
        if (item.point === 2) this.baketPoint.basket2.push(item.goods_article);
        if (item.point === 1) this.baketPoint.basket1.push(item.goods_article);
      }
      for (let j in basketJSON.basket.others) {
        let item = basketJSON.basket.others[j];
        if (item.point === 4) this.baketPoint.basket4.push(item.goods_article);
        if (item.point === 2) this.baketPoint.basket2.push(item.goods_article);
        if (item.point === 1) this.baketPoint.basket1.push(item.goods_article);
      }
    }
  },
};
async function request(url, method = "GET", data = null) {
  try {
    const headers = {};
    let body;

    if (data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    return await response.json();
  } catch (e) {
    console.warn("Error:", e.message);
  }
}
let today = new Date().toLocaleDateString();
let yesterday = new Date(new Date().getTime() - 86400000).toLocaleDateString();
let tomorrow = new Date(new Date().getTime() + 86400000).toLocaleDateString();
let afterTomorrow = new Date(
  new Date().getTime() + 172800000
).toLocaleDateString();
</script>